<template>
  <div class="my-header">
    <div class="bar">
      <img src="../assets/images/logo-s.png" />
      <a href="https://www.ibrpa.com/">返回首页</a>
    </div>
    <img :src="require($route.path=='/'?'@/assets/images/banner.png':'@/assets/images/banner-s.png')" width="100%" />
    <el-breadcrumb v-show="$route.path!='/' && $route.path!='/GradeList1' && $route.path!='/GradeList2' && $route.path!='/GradeList3'" separator="/">
      <el-breadcrumb-item class="bc-item" v-for="(item,i) in levelList" @click.native="goto(item.path)" :key="item.path"><span :style="{color:i!=levelList.length-1&&'#0052D9'}">{{item.title}}</span></el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
import pages from "../assets/js/apis/pages";
import {mapMutations} from "vuex"
export default {
  data() {
    return {
      levelList: [],
    };
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    ...mapMutations(["setTitle"]),
    getBreadcrumb() {
      if(this.$route.path!=="/"){
        pages.getPages(
          this.$route.path.startsWith("/parent")?
          this.$route.path.split("/")[2]:this.$route.path
        ).then(result=>{
          this.levelList = [ { title:"首页", path:"" },
          { title: "等级", path: "/GradeList1"},
          ...result.data];
          console.log(this.levelList[this.levelList.length-1].title);
          this.setTitle(this.levelList[this.levelList.length-1].title);
          console.log(this.levelList);
        })
      }
    },
    goto(path){
      if(path.trim()==""){
        this.$router.push("/")
      }else{
        this.$router.push(path)
      }
    }
  },
};
</script>
<style>
.my-header {
  width: 1110px;
  margin: 0 auto;
}
.my-header>img{
  margin-bottom:20px;
  cursor: crosshair;
  opacity: 0.9;
}
.my-header a {
  font-size: 16px;
  font-weight: bold;
  color: #2c3e50;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.my-header .bc-item:not(:last-child){
  cursor:pointer;
  height: 12px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0052D9;
}
.my-header a.router-link-exact-active {
  color: #42b983;
}
.my-header > .bar {
  height: 64px;
}

.my-header > .bar > img {
  width: 180px;
  height: 36px;
  margin-top: 13px;
  margin-bottom: 15px;
  cursor: crosshair;
}
.my-header > .bar > a {
  float: right;
  margin-top: 26px;
  margin-bottom: 23px;
  text-align: right;
  width: 105px;
  height: 15px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4c5668;
}
.my-header > .bar > a:hover {
  color: #0052D9;
}
</style>