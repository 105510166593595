import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import MyHeader from './components/MyHeader'
import MyFooter from './components/Footer'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'view-design/dist/styles/iview.css';
import ViewUI from "view-design"
import Filters from './assets/js/filters'

for (var key in Filters) {
  Vue.filter(key, Filters[key]);
}

Vue.use(ElementUI);
Vue.use(ViewUI);
// 添加请求拦截器
axios.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    //若token存在，则在每次请求头中加入token
    if (sessionStorage.getItem('token')) {
      config.headers.authorization= sessionStorage.getItem('token')
    }
    return config;
  },
  error => Promise.reject(error)
);
Vue.prototype.$axios = axios;

Vue.component("my-header", MyHeader);
Vue.component("my-footer", MyFooter)
Vue.config.productionTip = false
const IE11RouterFix = {
  methods: {
      hashChangeHandler: function() { 
        this.$router.push(
          window.location.hash.substring(
            1, 
            window.location.hash.length
          )
        ); 
      },
      isIE11: function() { 
        return !!window.MSInputMethodContext && !!document.documentMode; 
      }
  },
  mounted: function() { 
    if ( this.isIE11() ) { 
      window.addEventListener(
        'hashchange', 
        this.hashChangeHandler
      ); 
    } 
  },
  destroyed: function() { 
    if ( this.isIE11() ) { 
      window.removeEventListener(
        'hashchange', 
        this.hashChangeHandler
      ); 
    } 
  }
};
new Vue({
  router,
  store,
  render: h => h(App),
  // mixins: [IE11RouterFix],
  // render: function (createElement) {
  //   if ('-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style) {
  //     window.addEventListener('hashchange', () => {
  //       var currentPath = window.location.hash.slice(1)
  //       if (this.$route.path !== currentPath) {
  //         this.$router.push(currentPath)
  //       }
  //     }, false)
  //   }
  //   return createElement(App);
  // }
}).$mount('#app');


