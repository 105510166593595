<template>
  <div id="app">
    <my-header></my-header>
    <router-view />
    <my-footer></my-footer>
  </div>
</template>
<script>
export default {
  mounted() {
    console.log("环境是:"+process.env.NODE_ENV)
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
      console.log(`是IE浏览器`)
      window.addEventListener(
        "hashchange",
        () => {
          console.log(`触发hashchange事件`)
          let currentPath = window.location.hash.slice(1);
          console.log(currentPath);
          if (this.$route.path !== currentPath) {
            console.log(currentPath)
            this.$router.push(currentPath);
          }
        },
        false
      );
    }
  },
};
</script>
<style>
body,
ul,
li,
p {
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* background: url("./assets/images/bei.png");
  background-size: 100%; */
}
body,
ul,
li,
ol {
  margin: 0;
  padding: 0;
}
ul {
  list-style: none;
}
.label {
  font-size: 15px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  margin-bottom: 49px;
}
.select {
  width: 140px;
  margin-right: 20px;
}
.chb {
  margin-left: 30px;
  font-size: 12px;
}
.btn-back {
  margin-top: 80px;
  width: 120px;
  height: 38px;
  background: #146dff;
  border-radius: 4px;
  color: #fff;
  border: 0 !important;
}
</style>
