<template>
  <div class="fz-list">
    <a v-for="(b, i) of blocks" :key="i" :target="b.mod_name?'_blank':'_self'" class="block" :href="b.mod_name?`https://fangzhen.rpazj.com/#/${b.mod_name}`:'javascript:;'">
      <img :src="require(`@/assets/images/${b.pic}`)"><span>{{ b.name }}</span>
    </a>
  </div>
</template>
<script>
import modules from "@/assets/js/apis/modules";
export default {
  name: "FzList",
  data() {
    return {
      blocks: [],
    };
  },
  mounted(){
    modules.getModules(0).then(result=>this.blocks=result.data);
    console.log(this.blocks);
  }
};
</script>
<style lang="scss">
.fz-list {
  > .block {
    box-sizing: border-box;
    float: left;
    text-align: center;
    width: 240px;
    height: 141px;
    background: #ffffff;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin-top: 40px;
    margin-left: 50px;
    span{
      display:block;
      width:240px;
      height:45px;
      border: 1px solid #eeeeee;
      border-top:0;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top:-6px;
      padding-top:9px;
    }
    img{
      width:240px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:nth-child(4n + 1) {
      margin-left: 0;
    }
  }
}
</style>
