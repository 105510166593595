import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
    //获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法
VueRouter.prototype.push = function(location) {
    return originalPush.call(this, location).catch(err => err);
}
const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/GradeList1',
        name: 'GradeList1',
        component: () =>
            import ( /* webpackChunkName: "parent" */ '../components/GradeList1.vue'),
    },
    {
        path: '/GradeList2',
        name: 'GradeList2',
        component: () =>
            import ( /* webpackChunkName: "parent" */ '../components/GradeList2.vue'),
    },
    {
        path: '/GradeList3',
        name: 'GradeList3',
        component: () =>
            import ( /* webpackChunkName: "parent" */ '../components/GradeList3.vue'),
    },
    {
        path: '/parent/:mod',
        props: true,
        name: 'Parent',
        component: () =>
            import ( /* webpackChunkName: "parent" */ '../views/Parent.vue'),
        children: [{
                path: '/userslist2',
                name: 'UsersList2',
                component: () =>
                    import ( /* webpackChunkName: "userslist2" */ '../views/UsersList2.vue'),
                meta: { title: "用户信息列表" }
            },
            {
                path: '/adduser2',
                name: 'AddUser2',
                component: () =>
                    import ( /* webpackChunkName: "adduser2" */ '../views/AddUser2.vue'),
            },
            {
                path: '/login2',
                name: 'Login2',
                component: () =>
                    import ( /* webpackChunkName: "login2" */ '../views/Login2.vue'),
                meta: { title: "用户登录系统" }
            }
        ]
    }
]

const router = new VueRouter({
    // mode: 'history',
    routes
})

export default router