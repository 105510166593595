<template>
  <div style="margin-top: 100px">
    <div class="grade">
      <ul>
        <li>
          <router-link to="GradeList1">
            <section class="but1">
              <img src="../assets/images/按钮1.png" alt="" />
            </section>
          </router-link>
        </li>

        <li>
          <router-link to="GradeList2">
            <section class="but2">
              <img src="../assets/images/按钮2.png" alt="" />
            </section>
          </router-link>
        </li>

        <li>
          <router-link to="GradeList3">
            <section class="but3">
              <img src="../assets/images/按钮3.png" alt="" />
            </section>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import GradeList1 from "./GradeList1.vue";
import GradeList2 from "./GradeList2.vue";
import GradeList3 from "./GradeList3.vue";
export default {
  return: {
    GradeList1,
    GradeList2,
    GradeList3,
  },
};
</script>
<style lang="scss">
.grade ul li section {
  width: 300px;
  height: 200px;
  border-radius: 10px;
  font-family: Microsoft YaHei;
  float: left;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
    margin-top: -40px;
  }
  img:hover {
    transform: scale(1.1);
    transition: all 1s;
  }
}

.but2 {
  margin-left: 105px;
  margin-right: 105px;

}

</style>
