<template>
  <div class="gradelist">
    <div class="grade">
      <dl>
        <a href="/"><dd>返回</dd></a>
        <dd class="current">初级</dd>
        <router-link to="GradeList2"><dd>中级</dd></router-link>
        <router-link to="GradeList3"><dd>高级</dd></router-link>
      </dl>
    </div>
    <div class="test-list">
      <router-link
        v-for="(b, i) of blocks"
        :key="i"
        class="block"
        :to="`/parent/${b.mod_name}`"
      >
        {{ b.name }}
      </router-link>
    </div>
  </div>
</template>
<script>
import modules from "@/assets/js/apis/modules";
export default {
  name: "GradeList1",
  data() {
    return {
      blocks: [],
    };
  },
  mounted() {
    modules.getModules(1).then((result) => (this.blocks = result.data));
  },
};
</script>
<style lang="scss">
.gradelist {
  width: 1110px;
  margin: 0 auto;
  .grade {
    position: relative;
    width: 100%;
    height: 72px;
    box-shadow: 0 10px 10px -10px #e1e1e1;
    overflow: hidden;
    dl dd {
      float: left;
      height: 72px;
      line-height: 72px;
      margin-right: 52px;
      font-size: 17px;
      overflow: hidden;
    }
    .current a {
      color: #00a2e9;
    }
    a:hover {
      font-weight: bold;
      font-size: larger;
      color: blue;
    }
  }
  .test-list {
    .block {
      box-sizing: border-box;
      float: left;
      text-align: center;
      width: 200px;
      height: 80px;
      border: 1px solid #bbbbbb;
      line-height: 80px;
      font-size: 15px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-top: 40px;
      margin-left: 103px;
      border-bottom: 3px #5b99fb solid;
      border-radius: 10px;
      &:first-child {
        margin-left: 0;
      }
      &:nth-child(4n + 1) {
        margin-left: 0;
      }
      &:last-child {
        margin-bottom: 30px;
      }
      &:hover {
        background: #5b99fb;
        border: 1px solid #bbbbbb;
        color: #ffffff;
      }
    }
  }
}
</style>