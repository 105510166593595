<template>
  <div class="home">
    <div class="tabs">
      <span :class="{active:show=='test'}" @click="change('test')">实验流程</span>
      <span :class="{active:show=='fz'}" @click="change('fz')">仿真环境</span>
    </div>
    <test-list v-if="show=='test'"></test-list>
    <fz-list v-else></fz-list>
  </div>
</template>
<script>
import TestList from "@/components/TestList.vue"
import FzList from "@/components/FzList.vue"
export default{
  components:{ TestList, FzList },
  data(){
    return {
      show:"test"
    }
  },
  methods:{
    change(tname){
      this.show=tname;
    }
  }
}
</script>
<style lang="scss">
.home{
  width: 1110px;
  min-height:500px;
  margin: 0 auto;
  padding-bottom:100px;
  overflow:hidden;
  >.tabs{
    width:100%;
    border-bottom:1px solid #DDDDDD;
    margin-top:7px;
    height:54px;
    >span{
      cursor:pointer;
      display:inline-block;
      width:100px;
      height:54px;
      text-align:center;
      padding-top:13px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      &.active{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        border-bottom:4px solid #146DFF;
      }
    }
  }
}
</style>
