<template>
  <div class="gradelist">
    <div class="grade">
      <dl>
        <a href="/"><dd>返回</dd></a>
        <router-link to="GradeList1"><dd>初级</dd></router-link>
        <router-link to="GradeList2"><dd>中级</dd></router-link>
        <dd class="current">高级</dd>
      </dl>
    </div>
    <div class="test-list">
      <router-link
        v-for="(b, i) of blocks"
        :key="i"
        class="block"
        :to="`/parent/${b.mod_name}`"
      >
        {{ b.name }}
      </router-link>
    </div>
  </div>
</template>
<script>
import modules from "@/assets/js/apis/modules";
export default {
  name: "GradeList3",
  data() {
    return {
      blocks: [],
    };
  },
  mounted() {
    modules.getModules(3).then((result) => {
      this.blocks = result.data;
    });
  },
};
</script>
